<template>
  <div class="we-informational-card bg-white border-2 border-primary-brand-100 rounded-xl p-4 font-semibold text-sm text-primary-brand-300">
    
    <p v-if="!hasDiscount" class="mb-4" v-html="informationCardText.taxes_details" />

    <div v-else class="mb-4">
      <p>        
        <span class="text-xl font-bold">{{ discount.code }}</span>
        <span class="mt-2">
          Valor mensal de <span class="font-bold">R$ {{selectedPlan.price.full_price}}</span> por <span class="font-bold">{{ discount.monthly_fees }} meses.</span><br>
          Fidelidade: <span class="font-bold">12 meses</span>
        </span>   
      </p>      
    </div>

    <p class="mb-2" v-html="informationCardText.observation" />
    <p class="mb-2" v-html="informationCardText.general_information_details" />
    <p v-html="informationCardText.regulation_details"/>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const selectedPlan = computed(() => store.getters.getSelectedPlan)

const informationCardText = computed(() => {
  return {
    taxes_details: selectedPlan.value.product?.taxes_details,
    general_information_details: selectedPlan.value.product?.general_information_details,
    regulation_details: selectedPlan.value.product?.regulation_details,
    observation: selectedPlan.value.product?.observation
  }
})

const session = ref();
const discount = computed(() => store.getters.getDiscount);
const hasDiscount = computed(() => Object.keys(discount.value).length > 0)


</script>